const { debounce } = require('../sharable/Utilities');
const Sanitizer = require('../sharable/Sanitizer');

/** Local cache for getters/setters within this class **/
let cache = {
    storedSearchItems: []
};

class SiteSearch {
    constructor() {
        /** Private Properties **/
        this._sanitizer = new Sanitizer();

        /** Scoped Properties **/
        this.searchURL = "/talents/fetch_suggestive_search_results"
        this.searchContainer = document.querySelector(".siteSearch-container")
        this.searchForm = document.querySelector(".siteSearch-form")
        this.primarySearchInput = document.querySelector(".siteSearch-form-searchInput.nav-main-form-input");
        this.navMain = document.querySelector(".nav-main");
        this.searchInputs = [...document.querySelectorAll(".siteSearch-form-searchInput")];
        this.searchDropdownContainer = document.querySelector(".siteSearch-dropdownContainer")
        this.searchDropdown = document.querySelector(".siteSearch-dropdown")
        this.advancedFiltersButton = document.querySelector(".siteSearch-form-filtersButton")
        this.advancedFilters = document.querySelector(".siteSearch-filters")
        this.actionButtonsContainer = document.querySelector(".siteSearch-dropdown-actionButtonsContainer")
        this.suggestiveSearchContainer = document.querySelector(".siteSearch-dropdown-suggestiveSearchContainer")
        this.recentSearchesSection = document.querySelector(".siteSearch-dropdown-recentSearches")
        this.recentSearchContainer = document.querySelector(".siteSearch-dropdown-recentSearchContainer")
        this.filtersButtonContainer = document.querySelector(".siteSearch-dropdown-showFilters")
        this.urlSearchQuery = window.location.href.includes('/help/knowledge/search')
            ? ''
            : new URLSearchParams(window.location.search).get("keywords");
        this.path = window.location.pathname
        this.filterData = []
        this.staticSearchResults = [
            { query: "african american female", search_type: "talent" },
            { query: "british accent", search_type: "talent" },
            { query: "elearning", search_type: "talent" },
            { query: "rugged southern ", search_type: "talent" },
            { query: "british female", search_type: "talent" },
            { query: "anime voices ", search_type: "talent" },
            { query: "spanish voice over", search_type: "talent" },
            { query: "character voices", search_type: "talent" },
            { query: "cartoon animation", search_type: "talent" },
            { query: "girl next door", search_type: "talent" },
            { query: "documentary narration", search_type: "talent" },
            { query: "calming mediation voice ", search_type: "talent" },
            { query: "german accent", search_type: "talent" },
            { query: "audiobook narrator", search_type: "talent" },
            { query: "raspy voice", search_type: "talent" },
            { query: "young child", search_type: "talent" },
            { query: "friendly conversational ", search_type: "talent" },
            { query: "sports announcer", search_type: "talent" },
            { query: "video game", search_type: "talent" },
            { query: "french voice over", search_type: "talent" },
            { query: "young boy", search_type: "talent" },
            { query: "morgan freeman voice", search_type: "talent" },
            { query: "funny quirky upbeat", search_type: "talent" },
            { query: "movie trailer ", search_type: "talent" },
            { query: "authentic relatable ", search_type: "talent" },
            { query: "inspirational hopeful ", search_type: "talent" },
            { query: "gen z teen", search_type: "talent" },
            { query: "confident conversational", search_type: "talent" },
            { query: "androgynous", search_type: "talent" },
            { query: "authentic video narration", search_type: "project" },
            { query: "professional phone system", search_type: "project" },
            { query: "warm, search_soothing mediation", type: "project" },
            { query: "spanish", search_type: "project" },
            { query: "cartoon animation", search_type: "project" },
            { query: "friendly radio ad", search_type: "project" },
            { query: "documentary storyteller", search_type: "project" },
            { query: "sports announcement", search_type: "project" },
            { query: "friendly, search_authentic, warm", type: "project" },
            { query: "custom jingle", search_type: "project" },
            { query: "female ivr", search_type: "project" },
            { query: "political ad", search_type: "project" },
            { query: "video game character", search_type: "project" },
            { query: "social media ad", search_type: "project" },
            { query: "explainer video", search_type: "project" },
            { query: "movie trailer", search_type: "project" },
            { query: "emotional commercial", search_type: "project" },
            { query: "medical narration", search_type: "project" },
            { query: "corporate video", search_type: "project" },
            { query: "millenial online ad", search_type: "project" },
            { query: "funny ad", search_type: "project" },
            { query: "believable radio ad", search_type: "project" },
            { query: "british", search_type: "project" },
            { query: "podcast intro", search_type: "project" },
            { query: "conversational voice assistant", search_type: "project" },
            { query: "youthful ad", search_type: "project" },
            { query: "professional TV ad", search_type: "project" },
            { query: "believable character", search_type: "project" },
        ];
        let shuffledResults = this.staticSearchResults.sort(() => 0.5 - Math.random());
        let talentResults = shuffledResults.filter(result => result.search_type === "talent").slice(0, 2);
        let projectResults = shuffledResults.filter(result => result.search_type === "project").slice(0, 2);
        this.staticSelectedResults = [...talentResults, ...projectResults];

        this.packagesAbVariant = this.searchDropdown ? this.searchDropdown.dataset.packagesAb : null;
        if (this.packagesAbVariant === 'B') {
            this.staticSelectedResults = [...projectResults, ...talentResults]
        }

        if (this.searchContainer) {
            new Promise(async resolve => {
                await this.init();
                resolve();
            });
        }
    }

    /**
     * Sanitize Value
     * @param value
     * @returns {Promise<string>}
     */
    async sanitize(value) {
        return await this._sanitizer.sanitize_text(value, { allow_trim: false });
    }

    saveStoredSearchItems() {
        this.storedSearchItems = localStorage.getItem('storedVoicesSearchItems')
            ? JSON.parse(localStorage.getItem('storedVoicesSearchItems'))
            : [];
    }

    /**
     * GET Stored Search Items (Array)
     * @returns {*[]}
     */
    get storedSearchItems() {
        return cache.storedSearchItems;
    }

    /**
     * SET Stored Search Items (Array)
     * @param array
     */
    set storedSearchItems(array) {
        cache.storedSearchItems = array;
    }

    /**
     * Set Search Inputs Value with Sanitized Value
     * @param sanitizedValue
     */
    setSanitizedValueForSearchInputs(sanitizedValue) {
        this.searchInputs.forEach(searchInput => searchInput.value = sanitizedValue);
    }

    /**
     * Show Search Dropdown
     */
    showDropdown() {
        this.searchContainer.classList.add("searchOpen")

        if (window.innerWidth <= 992) {
            let timeout = setTimeout(() => {
                // Move focus to a different input field on mobile.
                document.querySelector(".siteSearch-form-desktop .siteSearch-form-searchInput").blur()
                document.querySelector(".siteSearch-mobile-formContainer .siteSearch-form-searchInput").focus()
                document.body.classList.add("expanded-search")
                this.navMain?.classList.add("expanded-search")
                clearTimeout(timeout)
            }, 100);
        }
    }

    /**
     * Hide Search Dropdown
     */
    hideDropdown() {
        this.searchContainer.classList.remove("searchOpen")
        this.searchContainer.classList.remove("filtersOpen")

        if (this.primarySearchInput.value.length > 1) {
            this.renderSuggestiveSearchResultsWithKeywords;
        } else {
            this.renderSuggestiveSearchResults();
            this.filtersButtonContainer.classList.remove("hidden");
        }

        this.primarySearchInput.blur();
    }

    /**
     * Render Search Action Buttons
     */
    renderActionButtons() {
        if(this.packagesAbVariant === 'B'){
            this.actionButtonsContainer.innerHTML = `
                <h3 class="siteSearch-dropdown-sectionHeader">Not sure what you're looking for?</h3>

                <div class="siteSearch-dropdown-actionButtons">
                    <a class="btn btn-default" href="/projects/search">
                        <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 2H11V1.5C11 0.671562 10.3284 0 9.5 0H6.5C5.67156 0 5 0.671562 5 1.5V2H1.5C0.671562 2 0 2.67156 0 3.5V12.5C0 13.3284 0.671562 14 1.5 14H14.5C15.3284 14 16 13.3284 16 12.5V3.5C16 2.67156 15.3284 2 14.5 2ZM6.5 1.5H9.5V2H6.5V1.5ZM1.5 3.6875C1.5 3.58395 1.58395 3.5 1.6875 3.5H14.3125C14.4161 3.5 14.5 3.58395 14.5 3.6875V7H1.5V3.6875ZM14.5 12.3125C14.5 12.4161 14.4161 12.5 14.3125 12.5H1.6875C1.58395 12.5 1.5 12.4161 1.5 12.3125V8.5H6V9.625C6 9.83209 6.16791 10 6.375 10H9.625C9.83209 10 10 9.83209 10 9.625V8.5H14.5V12.3125Z" fill="#4F5963"/>
                        </svg>

                        <span>See All</span> Packages
                    </a>
                    <a class="btn btn-default" href="/talents/search">
                        <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6667 0.779999H1.33333C0.597222 0.779999 0 1.37722 0 2.11333V11.8911C0 12.6272 0.597222 13.2244 1.33333 13.2244H14.6667C15.4028 13.2244 16 12.6272 16 11.8911V2.11333C16 1.37722 15.4028 0.779999 14.6667 0.779999ZM14.6667 11.8911H1.33333V2.11333H14.6667V11.8911ZM5.77778 7.00222C6.75833 7.00222 7.55556 6.205 7.55556 5.22444C7.55556 4.24389 6.75833 3.44667 5.77778 3.44667C4.79722 3.44667 4 4.24389 4 5.22444C4 6.205 4.79722 7.00222 5.77778 7.00222ZM3.28889 10.5578H8.26667C8.61111 10.5578 8.88889 10.3189 8.88889 10.0244V9.49111C8.88889 8.60778 8.05278 7.89111 7.02222 7.89111C6.72222 7.89111 6.50278 8.11333 5.77778 8.11333C5.03056 8.11333 4.85 7.89111 4.53333 7.89111C3.50278 7.89111 2.66667 8.60778 2.66667 9.49111V10.0244C2.66667 10.3189 2.94444 10.5578 3.28889 10.5578ZM10 8.78H13.1111C13.2333 8.78 13.3333 8.68 13.3333 8.55778V8.11333C13.3333 7.99111 13.2333 7.89111 13.1111 7.89111H10C9.87778 7.89111 9.77778 7.99111 9.77778 8.11333V8.55778C9.77778 8.68 9.87778 8.78 10 8.78ZM10 7.00222H13.1111C13.2333 7.00222 13.3333 6.90222 13.3333 6.78V6.33555C13.3333 6.21333 13.2333 6.11333 13.1111 6.11333H10C9.87778 6.11333 9.77778 6.21333 9.77778 6.33555V6.78C9.77778 6.90222 9.87778 7.00222 10 7.00222ZM10 5.22444H13.1111C13.2333 5.22444 13.3333 5.12444 13.3333 5.00222V4.55778C13.3333 4.43555 13.2333 4.33555 13.1111 4.33555H10C9.87778 4.33555 9.77778 4.43555 9.77778 4.55778V5.00222C9.77778 5.12444 9.87778 5.22444 10 5.22444Z" fill="#4F5963"/>
                        </svg>

                        <span>See All</span> Talent
                    </a>
                </div>
            `

        } else {
            this.actionButtonsContainer.innerHTML = `
                <h3 class="siteSearch-dropdown-sectionHeader">Not sure what you're looking for?</h3>

                <div class="siteSearch-dropdown-actionButtons">
                    <a class="btn btn-default" href="/talents/search">
                        <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6667 0.779999H1.33333C0.597222 0.779999 0 1.37722 0 2.11333V11.8911C0 12.6272 0.597222 13.2244 1.33333 13.2244H14.6667C15.4028 13.2244 16 12.6272 16 11.8911V2.11333C16 1.37722 15.4028 0.779999 14.6667 0.779999ZM14.6667 11.8911H1.33333V2.11333H14.6667V11.8911ZM5.77778 7.00222C6.75833 7.00222 7.55556 6.205 7.55556 5.22444C7.55556 4.24389 6.75833 3.44667 5.77778 3.44667C4.79722 3.44667 4 4.24389 4 5.22444C4 6.205 4.79722 7.00222 5.77778 7.00222ZM3.28889 10.5578H8.26667C8.61111 10.5578 8.88889 10.3189 8.88889 10.0244V9.49111C8.88889 8.60778 8.05278 7.89111 7.02222 7.89111C6.72222 7.89111 6.50278 8.11333 5.77778 8.11333C5.03056 8.11333 4.85 7.89111 4.53333 7.89111C3.50278 7.89111 2.66667 8.60778 2.66667 9.49111V10.0244C2.66667 10.3189 2.94444 10.5578 3.28889 10.5578ZM10 8.78H13.1111C13.2333 8.78 13.3333 8.68 13.3333 8.55778V8.11333C13.3333 7.99111 13.2333 7.89111 13.1111 7.89111H10C9.87778 7.89111 9.77778 7.99111 9.77778 8.11333V8.55778C9.77778 8.68 9.87778 8.78 10 8.78ZM10 7.00222H13.1111C13.2333 7.00222 13.3333 6.90222 13.3333 6.78V6.33555C13.3333 6.21333 13.2333 6.11333 13.1111 6.11333H10C9.87778 6.11333 9.77778 6.21333 9.77778 6.33555V6.78C9.77778 6.90222 9.87778 7.00222 10 7.00222ZM10 5.22444H13.1111C13.2333 5.22444 13.3333 5.12444 13.3333 5.00222V4.55778C13.3333 4.43555 13.2333 4.33555 13.1111 4.33555H10C9.87778 4.33555 9.77778 4.43555 9.77778 4.55778V5.00222C9.77778 5.12444 9.87778 5.22444 10 5.22444Z" fill="#4F5963"/>
                        </svg>

                        <span>See All</span> Talent
                    </a>

                    <a class="btn btn-default" href="/projects/search">
                        <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 2H11V1.5C11 0.671562 10.3284 0 9.5 0H6.5C5.67156 0 5 0.671562 5 1.5V2H1.5C0.671562 2 0 2.67156 0 3.5V12.5C0 13.3284 0.671562 14 1.5 14H14.5C15.3284 14 16 13.3284 16 12.5V3.5C16 2.67156 15.3284 2 14.5 2ZM6.5 1.5H9.5V2H6.5V1.5ZM1.5 3.6875C1.5 3.58395 1.58395 3.5 1.6875 3.5H14.3125C14.4161 3.5 14.5 3.58395 14.5 3.6875V7H1.5V3.6875ZM14.5 12.3125C14.5 12.4161 14.4161 12.5 14.3125 12.5H1.6875C1.58395 12.5 1.5 12.4161 1.5 12.3125V8.5H6V9.625C6 9.83209 6.16791 10 6.375 10H9.625C9.83209 10 10 9.83209 10 9.625V8.5H14.5V12.3125Z" fill="#4F5963"/>
                        </svg>

                        <span>See All</span> Packages
                    </a>
                </div>
            `
        }
    }

    /**
     * Render Search Action Buttons with Keywords
     * @param query
     */
    renderActionButtonsWithKeywords(query) {
        if(this.packagesAbVariant === 'B'){
            this.actionButtonsContainer.innerHTML = `
                <ul class="siteSearch-dropdown-submitKeywords siteSearch-dropdown-resultList">
                    ${this.renderResult({ query, search_type: "project" }, "project")}
                    ${this.renderResult({ query, search_type: "talent" }, "talent")}
                </ul>
            `;
        }else{
            this.actionButtonsContainer.innerHTML = `
                <ul class="siteSearch-dropdown-submitKeywords siteSearch-dropdown-resultList">
                    ${this.renderResult({ query, search_type: "talent" }, "talent")}
                    ${this.renderResult({ query, search_type: "project" }, "project")}
                </ul>
            `;
        }

        document.querySelectorAll(".siteSearch-dropdown-submitKeywords a").forEach(el => {
            el.addEventListener("click", e => {
                const searchType = el.href.includes("project") ? "project" : "talent";
                this.storeSearchItem(query, searchType);
            })
        })
    }

    /**
     * Render Search Result (in dropdown)
     * @param result
     * @param type
     * @returns {string}
     */
    renderResult(result, type = "suggestion") {
        let icons = {
            suggestion: `<path fill-rule="evenodd" clip-rule="evenodd" d="M15.8898 14.6524L12.0963 10.8588C12.0244 10.787 11.9307 10.7495 11.8307 10.7495H11.4182C12.4025 9.60891 12.9994 8.1246 12.9994 6.49968C12.9994 2.90923 10.0901 0 6.49968 0C2.90923 0 0 2.90923 0 6.49968C0 10.0901 2.90923 12.9994 6.49968 12.9994C8.1246 12.9994 9.60891 12.4025 10.7495 11.4182V11.8307C10.7495 11.9307 10.7901 12.0244 10.8588 12.0963L14.6524 15.8898C14.7993 16.0367 15.0368 16.0367 15.1836 15.8898L15.8898 15.1836C16.0367 15.0368 16.0367 14.7993 15.8898 14.6524ZM6.49968 11.4994C3.73732 11.4994 1.49993 9.26205 1.49993 6.49968C1.49993 3.73732 3.73732 1.49993 6.49968 1.49993C9.26205 1.49993 11.4994 3.73732 11.4994 6.49968C11.4994 9.26205 9.26205 11.4994 6.49968 11.4994Z" fill="#4F5963"/>`,
            recent: `<path fill-rule="evenodd" clip-rule="evenodd" d="M8 0C3.58065 0 0 3.58065 0 8C0 12.4194 3.58065 16 8 16C12.4194 16 16 12.4194 16 8C16 3.58065 12.4194 0 8 0ZM8 14.4516C4.43548 14.4516 1.54839 11.5645 1.54839 8C1.54839 4.43548 4.43548 1.54839 8 1.54839C11.5645 1.54839 14.4516 4.43548 14.4516 8C14.4516 11.5645 11.5645 14.4516 8 14.4516ZM9.99355 11.0839L7.25484 9.09355C7.15484 9.01935 7.09677 8.90323 7.09677 8.78065V3.48387C7.09677 3.27097 7.27097 3.09677 7.48387 3.09677H8.51613C8.72903 3.09677 8.90323 3.27097 8.90323 3.48387V8.05484L11.0581 9.62258C11.2323 9.74839 11.2677 9.99032 11.1419 10.1645L10.5355 11C10.4097 11.171 10.1677 11.2097 9.99355 11.0839Z" fill="#4F5963"/>`,
            talent: `<path fill-rule="evenodd" clip-rule="evenodd" d="M14.6667 0.779999H1.33333C0.597222 0.779999 0 1.37722 0 2.11333V11.8911C0 12.6272 0.597222 13.2244 1.33333 13.2244H14.6667C15.4028 13.2244 16 12.6272 16 11.8911V2.11333C16 1.37722 15.4028 0.779999 14.6667 0.779999ZM14.6667 11.8911H1.33333V2.11333H14.6667V11.8911ZM5.77778 7.00222C6.75833 7.00222 7.55556 6.205 7.55556 5.22444C7.55556 4.24389 6.75833 3.44667 5.77778 3.44667C4.79722 3.44667 4 4.24389 4 5.22444C4 6.205 4.79722 7.00222 5.77778 7.00222ZM3.28889 10.5578H8.26667C8.61111 10.5578 8.88889 10.3189 8.88889 10.0244V9.49111C8.88889 8.60778 8.05278 7.89111 7.02222 7.89111C6.72222 7.89111 6.50278 8.11333 5.77778 8.11333C5.03056 8.11333 4.85 7.89111 4.53333 7.89111C3.50278 7.89111 2.66667 8.60778 2.66667 9.49111V10.0244C2.66667 10.3189 2.94444 10.5578 3.28889 10.5578ZM10 8.78H13.1111C13.2333 8.78 13.3333 8.68 13.3333 8.55778V8.11333C13.3333 7.99111 13.2333 7.89111 13.1111 7.89111H10C9.87778 7.89111 9.77778 7.99111 9.77778 8.11333V8.55778C9.77778 8.68 9.87778 8.78 10 8.78ZM10 7.00222H13.1111C13.2333 7.00222 13.3333 6.90222 13.3333 6.78V6.33555C13.3333 6.21333 13.2333 6.11333 13.1111 6.11333H10C9.87778 6.11333 9.77778 6.21333 9.77778 6.33555V6.78C9.77778 6.90222 9.87778 7.00222 10 7.00222ZM10 5.22444H13.1111C13.2333 5.22444 13.3333 5.12444 13.3333 5.00222V4.55778C13.3333 4.43555 13.2333 4.33555 13.1111 4.33555H10C9.87778 4.33555 9.77778 4.43555 9.77778 4.55778V5.00222C9.77778 5.12444 9.87778 5.22444 10 5.22444Z" fill="#4F5963"/>`,
            project: `<path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 2H11V1.5C11 0.671562 10.3284 0 9.5 0H6.5C5.67156 0 5 0.671562 5 1.5V2H1.5C0.671562 2 0 2.67156 0 3.5V12.5C0 13.3284 0.671562 14 1.5 14H14.5C15.3284 14 16 13.3284 16 12.5V3.5C16 2.67156 15.3284 2 14.5 2ZM6.5 1.5H9.5V2H6.5V1.5ZM1.5 3.6875C1.5 3.58395 1.58395 3.5 1.6875 3.5H14.3125C14.4161 3.5 14.5 3.58395 14.5 3.6875V7H1.5V3.6875ZM14.5 12.3125C14.5 12.4161 14.4161 12.5 14.3125 12.5H1.6875C1.58395 12.5 1.5 12.4161 1.5 12.3125V8.5H6V9.625C6 9.83209 6.16791 10 6.375 10H9.625C9.83209 10 10 9.83209 10 9.625V8.5H14.5V12.3125Z" fill="#4F5963"/>`,
        }

        let types = {
            talent: "Talent",
            project: "Packages"
        }

        let hasArrow = type !== "suggestion"

        return `
            <li class="siteSearch-dropdown-searchResult">
                <a href="/${result.search_type}s/search?keywords=${result.query}">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        ${icons[type]}
                    </svg>
                    <span><span class="siteSearch-dropdown-searchResult-keyword">${result.query}</span> <span class="siteSearch-dropdown-searchResult-type">in <strong>${types[result.search_type]}</strong></span></span>

                    ${hasArrow ? `
                        <svg class="siteSearch-dropdown-searchResult-followIcon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.73437 3.11113L6.10843 3.73705C5.96028 3.88519 5.96028 4.1254 6.10843 4.27358L11.8212 10L6.10843 15.7264C5.96028 15.8746 5.96028 16.1148 6.10843 16.2629L6.73437 16.8889C6.88252 17.037 7.12273 17.037 7.27091 16.8889L13.8915 10.2683C14.0397 10.1201 14.0397 9.87992 13.8915 9.73174L7.27091 3.11113C7.12273 2.96296 6.88252 2.96296 6.73437 3.11113Z" fill="#4F5963"/>
                        </svg>
                    ` : ""}
                </a>
            </li>
        `
    }

    renderResultList(items, type = "suggestion", max = 0) {
        return items?.length > 0 ? items.map((result, index) => {
            if (max === 0 || index < max) {
                return this.renderResult(result, type)
            } else {
                return '';
            }
        }).join("") : null
    }

    renderSuggestiveSearchResults() {
        document.querySelector(".siteSearch-dropdown-suggestiveSearch").innerHTML = `
            <h3 class="siteSearch-dropdown-sectionHeader">Suggestions</h3>

            <ul class="siteSearch-dropdown-suggestiveSearchContainer siteSearch-dropdown-resultList">${this.renderResultList(this.staticSelectedResults)}</ul>
        `
    }

    formatSuggestionData(hits) {
        return hits.map(hit => ({ query: hit._source.keyword, search_type: "talent" }))
    }

    async renderSuggestiveSearchResultsWithKeywords(query) {
        if (query.length > 1) {
            try {
                let response = await fetch(`${this.searchURL}`, {
                    credentials: 'include',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    body: JSON.stringify({ search: { query } })
                })
                let json = await response.json()
                let {status: statusCode, data: { entities }} = json

                if (statusCode !== "success") return
                let results = this.renderResultList(this.formatSuggestionData(entities))

                if (results) {
                    document.querySelector(".siteSearch-dropdown-suggestiveSearch").innerHTML = `
                        <h3 class="siteSearch-dropdown-sectionHeader">Suggestions</h3>

                        <ul class="siteSearch-dropdown-suggestiveSearchContainer siteSearch-dropdown-resultList">${results}</ul>
                    `
                } else {
                    document.querySelector(".siteSearch-dropdown-suggestiveSearch").innerHTML = ``
                }

            } catch (error) {
                console.log("Something went wrong", error)
            }
        }
    }

    renderRecentsSearchResults(shouldRefetch = false) {
        if (this.storedSearchItems.length === 0) {
            return
        }

        let storedSearchItems = this.storedSearchItems;

        document.addEventListener("click", (e) => {
            const target = e.target.closest(".siteSearch-dropdown-recentSearch-clear")

            if (target) {
                e.preventDefault()
                e.stopPropagation()

                if (localStorage.getItem("storedVoicesSearchItems")) {
                    localStorage.removeItem("storedVoicesSearchItems");
                }

                this.saveStoredSearchItems();

                this.recentSearchesSection.innerHTML = "";
            }
        });

        const populateRecentSearchesSectionHTML = () => {
            this.recentSearchesSection.innerHTML = `
                <div class="siteSearch-dropdown-sectionHeader-container">
                    <h3 class="siteSearch-dropdown-sectionHeader">Recent Searches</h3>
                    <button type="button" class="siteSearch-dropdown-recentSearch-clear">Clear</button>
                </div>

                <ul class="siteSearch-dropdown-recentSearchContainer siteSearch-dropdown-resultList">${this.renderResultList(storedSearchItems, "recent", 4)}</ul>
            `;
        };

        if (shouldRefetch) {
            storedSearchItems = this.storedSearchItems;
            populateRecentSearchesSectionHTML();
        } else {
            populateRecentSearchesSectionHTML();
        }

    }

    clearRecentSearches() {
        let recentSearchClear = document.querySelector(".siteSearch-dropdown-recentSearch-clear")

        recentSearchClear?.addEventListener("click", (e) => {
            e.preventDefault()
            e.stopPropagation()
            if (localStorage.getItem("storedVoicesSearchItems")) {
                localStorage.removeItem("storedVoicesSearchItems");
            }

            this.saveStoredSearchItems();

            this.recentSearchesSection.innerHTML = "";
        })
    }

    async storeSearchItem(query, selectedSearchType = "talent") {
        const sanitizedSearchQuery = await this.sanitize(query);

        let selectedSearchTypeName = "Voice Over"
        let storedSearchItems = this.storedSearchItems
        const isUnique =
            storedSearchItems.length === 0
                ? true
                : !storedSearchItems.some(function(storedSearchItem) {
                    return (
                        storedSearchItem.query === sanitizedSearchQuery
                        && storedSearchItem.search_type === selectedSearchType
                    );
                })

        if (!isUnique || sanitizedSearchQuery.length === 0) {
            return false
        }

        storedSearchItems.unshift({
            query: sanitizedSearchQuery,
            search_type: selectedSearchType,
            search_type_name: selectedSearchTypeName
        });

        if (storedSearchItems.length > 4) {
            storedSearchItems.pop()
        }

        localStorage.setItem('storedVoicesSearchItems', JSON.stringify(storedSearchItems));

        this.renderRecentsSearchResults(true)

        return true
    }

    showAdvancedFilters() {
        if (this.filterData.length === 0) {
            Promise.all([
                this.fetchFilterData("categories"),
                this.fetchFilterData("languages"),
                this.fetchFilterData("accents"),
                this.fetchFilterData("voice_ages"),
            ]).then(response => {
                this.filterData = [
                    ...response,
                    {
                        filter: "voice_gender",
                        data: [
                            { text: 'Male', value: 'male' },
                            { text: 'Female', value: 'female' }
                        ]
                    },
                    {
                        filter: "delivery_time",
                        data: [
                            { text: '24hr', value: '1d' },
                            { text: 'Up to 3 Days', value: '3d' },
                            { text: 'Up to 7 Days', value: '7d' }
                        ]
                    }
                ];

                const html = `
                    <div class="siteSearch-filters-projectFilters ${this.packagesAbVariant === 'A' ? 'hidden' : ''}">
                        <div class="siteSearch-filters-projectFilters-formGroup">
                            <div class="form-group">
                                <label class="form-label">
                                    Price (Min)
                                </label>
                                <input
                                    type="number"
                                    min="0"
                                    class="form-control"
                                    name="filters_price_min"
                                    id="filters_price_min"
                                    placeholder="0.00"
                                />
                            </div>
                            <div class="form-group">
                                <label class="form-label">
                                    Price (Max)
                                </label>
                                <input
                                    type="number"
                                    min="0"
                                    class="form-control"
                                    name="filters_price_max"
                                    id="filters_price_max"
                                    placeholder="0.00"
                                />
                            </div>
                        </div>
                        ${this.renderSelectElement(this.filterData[5].filter, this.filterData[5].data)}
                    </div>
                    ${this.renderSelectElement(this.filterData[0].filter, this.filterData[0].data)}
                    ${this.renderSelectElement(this.filterData[1].filter, this.filterData[1].data)}
                    ${this.renderSelectElement(this.filterData[2].filter, this.filterData[2].data)}
                    <div class="siteSearch-filters-projectFilters-formGroup">
                        ${this.renderSelectElement(this.filterData[4].filter, this.filterData[4].data)}
                        ${this.renderSelectElement(this.filterData[3].filter, this.filterData[3].data)}
                    </div>
                    <div class="form-group">
                        <label class="form-label">
                            Keywords
                        </label>
                        <input
                            type="text"
                            class="form-control"
                            name="filters_keywords"
                            id="filters_keywords"
                            placeholder="Type something..."
                        />
                    </div>
                `;

                document.querySelector(".siteSearch-filters-inputs").innerHTML = html;

                // Sanitize Keywords Input (unfocus)
                const keywordsInput = document.querySelector("#filters_keywords");
                keywordsInput.addEventListener('blur', async (e) => {
                    const sanitizedValue = await this.sanitize(e.target.value);
                    keywordsInput.value = sanitizedValue;
                    this.setSanitizedValueForSearchInputs(sanitizedValue);
                });

                document.querySelectorAll(".siteSearch-filters-projectFilters input[type='number']").forEach(el => {
                    el.addEventListener("change", e => {
                        const value = e.target.value;
                        e.target.value = parseInt(value) < 0 ? 0 : value;
                    })
                })

                this.searchContainer.classList.add("filtersOpen");
                this.showDropdown();
            })
        } else {
            this.searchContainer.classList.add("filtersOpen");
            this.showDropdown();
        }
    }

    renderSelectElement(filter, data) {
        let labels = {languages: "Language", voice_gender: "Voice Gender", voice_ages: "Voice Age", accents: "Accent", categories: "Category", delivery_time: "Delivery Time"}

        return `
            <div class="form-group">
                <label for="filter_${filter}">${labels[filter]}</label>

                <select name="filter_${filter}" class="search-filter-input form-control"}>
                    <option value="" disabled selected hidden>Select...</option>
                    ${data
                        .map(({ value, text }) => {
                            return `<option value="${value}">${text}</option>`;
                        })
                        .join('')
                    }
                </select>
            </div>
        `
    }

    hideAdvancedFilters() {
        this.searchContainer.classList.remove("filtersOpen")
    }

    async fetchFilterData(filter) {
        try {
            let url = `/api/search_filters/${filter}`
            let response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            let { data } = await response.json()

            return { filter, data }
        } catch (error) {
            console.error(error)
        }
    }

    renderAdnvacedFilters() {
        document.querySelector(".siteSearch-filters-searchBy").insertAdjacentHTML("afterend",
                `
                    <section class="siteSearch-filters-inputs"></section>
                    <section class="siteSearch-filters-actions">
                        <button type="button" class="siteSearch-filters-cancelButton btn btn-default">Cancel</button>
                        <button type="button" class="siteSearch-filters-searchButton btn btn-primary">Search</button>
                    </section>
                `
        )
    }

    initAdvancedFilters() {
        this.renderAdnvacedFilters()

        document.querySelectorAll(".siteSearch-form-filtersButton").forEach(el => {
            el.addEventListener("click", (e) => {
                this.showAdvancedFilters()
            })
        })

        document.querySelector(".siteSearch-dropdown-showFiltersButton").addEventListener("click", (e) => {
            this.showAdvancedFilters()
        })

        document.querySelector(".siteSearch-filters-cancelButton").addEventListener("click", (e) => {
            this.searchForm.reset()
            this.hideDropdown()
            this.hideAdvancedFilters()
        })

        document.querySelector("#filter_talent").addEventListener("change", (e) => {
            document.querySelector(".siteSearch-filters-projectFilters").classList.add("hidden")
        })

        document.querySelector("#filter_project").addEventListener("change", (e) => {
            document.querySelector(".siteSearch-filters-projectFilters").classList.remove("hidden")
        })

        // Submit Advanced Filters Search
        document.querySelector(".siteSearch-filters-searchButton").addEventListener("click", async (e) => {
            e.preventDefault();
            let inputs = document.querySelector(".siteSearch-filters").querySelectorAll("input, select");
            let data = new FormData();
            for (const el of inputs) {
                const name = el.name;
                let value = el.value;
                if (name === 'filters_keywords') {
                    value = await this.sanitize(value);
                }
                data.append(name, value);
            }

            let selectors = document.querySelectorAll("input[name='filter_type']");
            let searchType = "talents";

            selectors.forEach(el => {
                if (el.value === "projects" && el.checked === true) searchType = "projects";
            })

            data = Object.fromEntries(data)
            let url = `/${searchType}/search/?keywords=${data.filters_keywords}`

            if (data.filters_keywords.length > 0) {
                this.storeSearchItem(data.filters_keywords, searchType.slice(0, -1));
            }

            let searchParams = {
                filter_accents: "accent_id",
                filter_categories: "category_ids",
                filter_languages: "language_ids",
                filter_voice_gender: "voice_gender",
                filter_voice_ages: "voice_age_ids",
                filters_price_min: "budget_min",
                filters_price_max: "budget_max",
                filter_delivery_time: "delivery_time",
            };

            Object.keys(data)
                .filter(key => !["keywords", "filters_keywords", "filter_type"].includes(key))
                .forEach(key => {
                    if (!data[key]) return;
                    url = `${url}&${searchParams[key]}=${data[key]}`;
                });

            window.location.href = url;
        });
    }

    initDropdown() {
        document.querySelector(".nav-main-submenu-search")?.addEventListener("click", (e) => {
            this.searchContainer.classList.add("siteSearch-mobileVisible")

            if (window.innerWidth <= 992) {
                let timeout = setTimeout(() => {
                    document.querySelector(".siteSearch-mobile-formContainer .siteSearch-form-searchInput").focus()

                    clearTimeout(timeout)
                }, 100);
            }
        })

        document.querySelector(".siteSearch-mobile-closeButton")?.addEventListener("click", (e) => {
            this.searchContainer.classList.remove("siteSearch-mobileVisible", "searchOpen");
            document.body.classList.remove("expanded-search");
            document.body.classList.remove("expanded");
            this.navMain?.classList.remove("expanded-search");
            this.hideAdvancedFilters();
        })

        document.querySelector(".siteSearch-form-clearKeywords").addEventListener("click", () => {
            this.searchForm.classList.remove("hasKeywords");
            this.recentSearchesSection.classList.remove("hidden");
            this.filtersButtonContainer.classList.remove("hidden");
            this.renderSuggestiveSearchResults();
            this.renderActionButtons();
            this.hideDropdown();
        })

        this.primarySearchInput.addEventListener("focus", (e) => {
            this.showDropdown();
        })

        document.addEventListener("click", (e) => {
            const withinBoundaries = e.composedPath().includes(this.searchContainer);
            if (!withinBoundaries) {
                this.hideDropdown();
            }
        })

        document.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                this.hideDropdown();
            }
        })

        this.searchForm.addEventListener("submit", async (e) => {
            const searchType = (window.location.pathname.includes("projects/search") || this.packagesAbVariant === 'B')
                ? "project"
                : "talent";

            this.hideDropdown();

            this.storeSearchItem(this.primarySearchInput.value, searchType);

            // Update search input (sanitized)
            const sanitizedValue = await this.sanitize(this.primarySearchInput.value);
            this.setSanitizedValueForSearchInputs(sanitizedValue);
        })

        document.querySelector(".siteSearch-mobile-formContainer form").addEventListener("submit", async (e) => {
            e.stopPropagation();
            e.preventDefault();
            const searchType = (window.location.pathname.includes("projects/search") || this.packagesAbVariant === 'B') ? "project" : "talent";
            const { value = '' } = e.target.querySelector("input[name='keywords']");
            const sanitizedValue = await this.sanitize(value);

            this.storeSearchItem(sanitizedValue, searchType);
            let params = new URLSearchParams(window.location.search);
            let newParams = {};

            if (sanitizedValue.length > 0) {
                newParams["keywords"] = sanitizedValue;
            }

            params.forEach((param, key) => {
                if (key === "keywords") {
                    newParams["keywords"] = sanitizedValue;
                    return;
                }

                newParams[key] = param;
            })

            newParams = new URLSearchParams(newParams).toString();
            window.location.href = `${searchType === "talent" ? "/talents/search" : "/projects/search"}?${newParams}`;
        })
    }

    /**
     * Update Form Action
     */
    updateFormTarget() {
        const path = this.path;

        if (path.includes("projects/search")) {
            this.searchForm.action = "/projects/search";
            document.querySelector(".siteSearch-mobile-formContainer form").action = "/projects/search";
        }

        if (path.includes("talents/search")) {
            this.searchForm.action = "/talents/search";
            document.querySelector(".siteSearch-mobile-formContainer form").action = "/talents/search";
        }
    }

    /**
     * Handle Search Input
     */
    handleSearchInput() {
        /**
         * Handle UI Changes onChange of search input
         * @param value
         * @param isSanitized
         */
        const onChangeSearchInput = (value, isSanitized = false) => {
            this.updateFormTarget();

            if (value.length === 0) {
                this.searchForm.classList.remove("hasKeywords");
                document.querySelector(".siteSearch-mobile-formContainer").classList.remove("hasKeywords");
                document.querySelector(".siteSearch-form-clearKeywords").classList.remove("show");
                this.recentSearchesSection.classList.remove("hidden");
                this.filtersButtonContainer.classList.remove("hidden");
                this.renderActionButtons();
                this.renderSuggestiveSearchResults();
            } else {
                this.searchForm.classList.add("hasKeywords");
                document.querySelector(".siteSearch-mobile-formContainer").classList.add("hasKeywords");
                document.querySelector(".siteSearch-form-clearKeywords").classList.add("show");
                this.recentSearchesSection.classList.add("hidden");
                this.filtersButtonContainer.classList.add("hidden");
                if (isSanitized) this.renderActionButtonsWithKeywords(value);
            }

            if (isSanitized && document.querySelector("#filters_keywords")) {
                document.querySelector("#filters_keywords").value = value;
            }

            if (isSanitized) {
                this.renderSuggestiveSearchResultsWithKeywords(value);
            }
        };

        this.searchInputs.forEach(el => {
            // Keyup event, handle updating UI elements without sanitizing string. Otherwise Async/Await causes lag when user types in searchbar.
            el.addEventListener('keyup', (e) => {
                const { value } = e.target;
                onChangeSearchInput(value, false);
            });

            // Keyup event, handle updating UI elements after sanitization has completed from Async/Await call.
            // Note: The sanitized string will be updated in the searchbar element on search submit
            el.addEventListener('keyup', debounce(async (e) => {
                const { value } = e.target;
                const sanitizedValue = await this.sanitize(value);
                onChangeSearchInput(sanitizedValue, true);
            }, 250));
        });
    }

    /**
     * Initialize Search
     * @returns {Promise<void>}
     */
    async init() {
        this.saveStoredSearchItems();
        this.renderActionButtons();
        this.renderSuggestiveSearchResults();
        this.renderRecentsSearchResults();
        this.clearRecentSearches();
        this.handleSearchInput();
        this.initAdvancedFilters();
        this.initDropdown();

        if (this.urlSearchQuery) {
            const sanitizedValue = await this.sanitize(this.urlSearchQuery);
            this.setSanitizedValueForSearchInputs(sanitizedValue);
        }
    }
}

new SiteSearch();
